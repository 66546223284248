// Use this file for your custom mixins/functions
// Use this file for your custom mixins/functions
//MIXINS



/*
*
*
*
*
* DELETE 'GREEN' COLOR FROM STYLES
*
*
*
*/
@mixin section {
  padding-top:rem(30px);
  padding-bottom:rem(30px);
  @include breakpoint(small) {
    padding-top:rem(60px);
    padding-bottom:rem(60px);  
  }
  @include breakpoint(medium) {
    padding-top:rem(100px);
    padding-bottom:rem(100px);
  }
  @include breakpoint(huge) {
    padding-top:rem(130px);
    padding-bottom:rem(130px);
  }
  @include breakpoint(full) {
    padding-top:rem(150px);
    padding-bottom:rem(150px);
  }
  @include breakpoint(fullHD) {
    padding-top:rem(230px);
    padding-bottom:rem(230px);
  }
  @media only screen and (max-height: 700px) {
	 padding-top:rem(60px);
    padding-bottom:rem(60px);
  }
  @media only screen and (max-height: 500px) {
	 padding-top:rem(30px);
    padding-bottom:rem(30px);
  }
}
@mixin sectionSmall {
  padding-top:rem(30px); 
  padding-bottom:rem(30px);
  @include breakpoint(small) {
    padding-top:rem(40px);
    padding-bottom:rem(40px);  
  }
  @include breakpoint(medium) {
    padding-top:rem(60px);
    padding-bottom:rem(60px);
  }
  @include breakpoint(huge) {
    padding-top:rem(100px);
    padding-bottom:rem(100px);
  }
  @media only screen and (max-height: 700px) {
    padding-top:rem(40px);
    padding-bottom:rem(40px);
  }
  @media only screen and (max-height: 500px) {
    padding-top:rem(30px);
    padding-bottom:rem(30px);
  }
}
@mixin sectionHeader {
  padding-top:rem(30px); 
  padding-bottom:rem(60px);
  @include breakpoint(small) {
    padding-top:rem(40px);
    padding-bottom:rem(70px);  
  }
  @include breakpoint(medium) {
    padding-top:rem(60px);
    padding-bottom:rem(90px);
  }
  @include breakpoint(huge) {
    padding-top:rem(130px);
    padding-bottom:rem(160px);
  }
  @media only screen and (max-height: 700px) {
    padding-top:rem(40px);
    padding-bottom:rem(40px);
  }
  @media only screen and (max-height: 500px) {
    padding-top:rem(30px);
    padding-bottom:rem(30px);
  }
}

@mixin sectionVerySmall {
  padding-top:rem(20px); 
  padding-bottom:rem(20px);
  @include breakpoint(small) {
    padding-top:rem(30px);
    padding-bottom:rem(30px);  
  }
  @include breakpoint(medium) {
    padding-top:rem(40px);
    padding-bottom:rem(40px);
  }
  @include breakpoint(huge) {
    padding-top:rem(60px);
    padding-bottom:rem(60px);
  }
  @media only screen and (max-height: 700px) {
    padding-top:rem(30px);
    padding-bottom:rem(30px);
  }
  @media only screen and (max-height: 500px) {
    padding-top:rem(20px);
    padding-bottom:rem(20px);
  }
}
// SECTION
/////////////////////////////
.section {
  @include section;
}
.sectionSmall {
  @include sectionSmall;
}
.sectionHeader {
  @include sectionHeader;
}
.sectionVerySmall {
  @include sectionVerySmall;
}
.section, .sectionSmall {
  position: relative;
  // &:before {
  //   content: "";
  //   background-color: $light;
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   z-index: -1;
  // }

  /*
  &.sectionColor {
    color: $light;
    background: $dark;
    a {
      color: $light;
      transition: 300ms opacity;
      &:hover {
      	opacity: .6;
      }
    }
  }
  &.sectionHalf {
    &:before {
      content: "";
      background: $light;
      position: absolute;
      top: 33%;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      @include breakpoint(giant) {
        background: $light image-url('layout/bgVector1.png') center center no-repeat;
        background-size: contain;
        background-position: top rem(10px) right 12vw;
        top: 28%;
      }
      @include breakpoint(huge) {
        top: 33%;
      }
      @include breakpoint(full) {
        background-position: top rem(10px) right 16vw;
      }
    }
  }
  &.sectionHalf2 {
    &:before {
      content: "";
      background: $light;
      position: absolute;
      top: 0;
      bottom: 35%;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
  &.sectionHalf3 {
    &:before {
      content: "";
      background: $light;
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
  &.sectionHalf4 {
    &:before {
      content: "";
      background: $lightMedium;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      @include breakpoint(large) {
        background: $lightMedium image-url('layout/bgVector2.jpg') center center no-repeat;
        background-size: contain;
        background-position: top rem(10px) left 4vw;
      }
      @include breakpoint(full) {
        background-position: top rem(10px) left 8vw;
      }
    }
  }
  &.lastSection {
    &:before {
      content: "";
      background: $light;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -2;
    }
  }  
  &.sectionMedium {
    background: $medium;
  }
  &.sectionImg {
  	@include responsiveImage('layout/bgSection.jpg', (tiny, medium), true);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $dark;
  }
  */
  &.noGapTop {
    padding-top: 0;
  }
  &.noGapBottom {
    padding-bottom: 0;
  }
}

 

//GLOBALS
body {
	font-size: rem(14px);
	@include breakpoint(medium) {
	  font-size: rem(16px);
	}
	@include breakpoint(giant) {
	  font-size: rem(18px);
	}
}
hr, .hr {
  background: $border;
  margin: 3rem 0;
}
dl {
  &.styledList {
    dt,
    dd {
  
      //background: rgba($dark, .05);
    }
  }
} 


// Form styles
form.default {
	fieldset {
		margin-bottom: 0!important;
	}
}

// Mitwachsende Box für .col
body:not(.cmsBackend) .contentBox {
    // z-index: 1;
    position: relative;
    padding:rem($baseGap*2); 
    // margin:rem($baseGap*2) 0;
    @include breakpoint(large) {
    	padding:rem($baseGap*3) rem($baseGap*4);
    }
    > *:last-child {
    	margin-bottom: 0;
    }
    &:before {
        content:'';
        background: green;
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin:auto;
        width: calc(100% - #{rem($baseGap*2)}); 
        height: 100%;
        z-index: -1;
        border: 6px solid green;
        border-radius: 6px;
    }
}