// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
	transition: all 300ms ease-in-out;
}
%buttonHover {
	// Link Hover
	color: $secondary;
	background-color: none;
	transition: all 300ms ease-in-out;
}
%buttonActive {
	// Link Active
	color: $secondary;
	background-color: none;	
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)
$barPadding: 23px;

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							@include breakpoint($break, max) {
								height: 100%;
							}
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
		span {
			color: $medium;
			padding-left: rem(10px);
			padding-right: rem(10px);
		}
	}
}


// Navigation Desktop Styles
////

@include breakpoint($break, max) {

	#navigation {

		overflow: hidden;
    	overflow-y: scroll;
		@include breakpoint(tiny) {
			max-width: 70vw;
		}
		@include breakpoint(small) {
			max-width: 60vw;
		}
		
		.nav {
			.branding {
		    	display: none;
		    }
		    .infoNav {	
		    	padding-right: rem(35px);
		    	text-align: right;  
		    	@include breakpoint($break, max) {
		    		padding-bottom: rem(15px);
		    		border-bottom: 1px solid rgba(204, 204, 204, .5);
		    	}
	    		@include breakpoint(large) {
	    			font-size: ($baseFontSize)*.8;
	    		}
	    		dl.openingHours {
	    			display: flex;
	    			justify-content: flex-end;
	    			dt,dd {
	    				@include breakpoint(tiny) {
	    					display: inline-flex;
	    				}
	    			}
	    			dt {
	    				@include breakpoint(large, max) {
	    					font-weight: 500;
	    				}
	    			}
	    			dd {
	    				padding-left: rem(10px);
	    			}
	    		}
	    		a, span {
	    			&.phone, &.maillink {
	    				display: inline-flex;
	    				justify-content: flex-end;
	    			}
	    			&.maillink {
	    				padding-left: rem(10px);
	    				@include breakpoint(tiny) {
	    					padding-left: rem(20px);
	    				}
	    			}
	    		}
	    		.daySeperator {
	    			display: none;
	    		}
		    	.scroll & {
		    		@include breakpoint(full) {
		    			margin-bottom: rem(15px);
			        	transition: all 900ms ease-in-out;
		    		}			        
		      	}
	    	}
		}
		.naviMain {
			padding-top: rem(15px);
			margin-bottom: 150px;
		}
    }
}

@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		.nav {							
			padding-top: $barPadding;
			padding-bottom: $barPadding;
			transition: all 450ms ease-in-out;
			
			ul {

				&.navi {

					li {
						.startseite {
							@include breakpoint(large) {
								display:none;
							}
							@include breakpoint(giant) {
								display: block;
							}
						}
						body.versteigerung & {
	
							&.versteigerung > a {
								color:$secondary;
							}
						}
						body.immobilienangebote & {
	
							&.immobilienangebote > a {
								color:$secondary;
							}
						}
					}
				}
			}
			.scroll & { 
		      	padding-bottom: 0px;
		      	padding-top: 0px;				
		      	transition: all 450ms ease-in-out;    
		      	box-shadow: 0 0 25px 0 rgba(0, 0, 0, .2);    
	    	}

	    	.brandPos {
	    		display: flex; 
	    		flex-direction: column;
	    		align-items: center;
	    	}

			.branding {
				//display: none;
			}

	    	.infoNav {	 		
	    		transition: all 900ms ease-in-out;
	    		@include breakpoint(large) {
	    			font-size: ($baseFontSize)*.8;
	    		}
	    		@include breakpoint(giant) {
	    			font-size: ($baseFontSize)*.9;
	    		}
	    		@include breakpoint(full) {
					font-size: rem($baseFontSize);
	    		}
	    		dl.openingHours {
	    			display: inline-flex;
	    			dt,dd {
	    			}
	    			dt {
	    			}
	    			dd {
	    				padding-left: rem(10px);
	    			}
	    		}
	    		a, span {
	    			&.phone {
		    			padding: 0 rem(30px);
		    		}
	    		}
	    		
		    	.scroll & {
		    		@include breakpoint(full) {
		    			margin-bottom: rem(5px);
			        	transition: all 900ms ease-in-out;
		    		}			        
		      	}
		      	.hr {
		      		@include breakpoint(large) {
		      			display: none;
		      		}
		      	}
	    	}
		}	
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $light;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	
	@include breakpoint($break) {
		display: none;
	}
	@include breakpoint(large, max) {
		box-shadow: 0 0 15px 0 rgba(0,0,0,.4);
	}
	.brandingFormat {
		max-height: rem(45px);
		@include breakpoint($break, max) {
			max-width: rem(100px);
			max-height: auto;
		}
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////

.naviAdd {
    padding: rem(25px) 0 0 rem(25px);
    font-size: $baseFontSize;
    @include breakpoint(full, max) {
    	border-top: $baseBorder;
    }
    @include breakpoint(tiny) {
        display: flex;
        justify-content: center;
        margin:0 auto;
    }
    @include breakpoint(large) {
    	display: flex;
		flex-direction: row;
		margin-top: 6em;
		margin-left: -7em!important;
		padding: rem(25px) 0 0 rem(25px);
	}
	@include breakpoint(medium) {
    	display: flex;
		justify-content: center;
		margin: 0px auto;
		margin-top: 6em;
	}
	@media (min-width: 1200px) and (max-width: 1364px) {
    	display: flex;
		justify-content: center;
		margin: 0px auto;
		margin-top: 6em;
		margin-left: -5em;
    }
    @include breakpoint(huge) {
    	padding: rem(25px) 0 0 rem(50px);
    	justify-content: flex-start;
		flex-direction: row;
		margin-left: -3em!important;
	}
	@media (min-width: 1620px){
		padding: rem(25px) 0 0 rem(50px);
    	justify-content: flex-start;
		flex-direction: row;
		margin-left: -2em!important;
	}
    @include breakpoint(fullHD) {
		padding: 0 0 0 0;
		margin-left: -4em!important;
    }
    li {
        @include breakpoint(small) {          
            &:first-child:before {
                display: none;
            }
        }
        a {
            color:$dark;
            text-decoration: none;
            display: inline-block;
            padding-right:rem(40px);
            @include breakpoint (large) {
            	padding-right:rem(40px);
            }
            &.active, &:hover  {
                color:$secondary;
            }
        }
    }
}