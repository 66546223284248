// Use this for default Icomoon

@include fontFaceV2(
    $fontName: "icomoon",
    $fileName: "icomoon", 
    $types: ("ttf","woff", "eot", "svg"),
    $style: normal
);

// Example font-face for Roboto
// @include fontFaceV2(
//     $fontName: "Roboto", 
//     $fileName: "roboto-v19-latin", 
//     $weights: ("400", "700", "900", "900|italic"), 
//     $types: all
// );

@include fontFaceV2(
    $fontName: "Jost", 
    $fileName: "jost-v3-latin", 
    $weights: ("300", "400", "500"), 
    $types: ("woff", "woff2")
);