#pageWrap {
	padding-top: rem($barHeight);
	@include breakpoint(large) {
		padding-top: rem(108px);
	}
	@include breakpoint(fullHD) {
		padding-top: rem(134px);
	}

	body.cmsBackend & {
		padding-top:rem(250px)!important;
	}
}
// HEADER
//////////////////////////////

#header {
	position: relative;
	.claimContainer {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;				
	}
}

.teaserBox {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	color: $light;
	min-height: rem(200px);
	line-height: 100%;	
	.teaserBoxShade {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;	
		
		.teaserBoxContent {
			text-align: center;
			line-height: 30px;
			cursor: default;
			span {
				display: block;
			}	
			.teaserHeadline {
				color: $secondary;
				font-size: ($h0Size)*.35;
				line-height: 100%;
				display: block;
				@include breakpoint(tiny) {
					font-size: ($h0Size)*.5;
				}
				@include breakpoint(small) {
					font-size: ($h0Size)*.6;
				}
				@include breakpoint(large) {
					font-size: ($h0Size)*.7;
				}
				@include breakpoint(giant) {
					font-size: ($h0Size)*.8;
				}
				@include breakpoint(full) {
					font-size: $h0Size;
				}
			}
			.teaserSubHeadline {
				font-size: ($subh0Size)*.3;
				padding-bottom: rem(20px);
				@include breakpoint(tiny) {		
					font-size: ($subh0Size)*.5;
					padding-top: rem(5px);			
					padding-bottom: rem(25px);
				}
				@include breakpoint(small) {	
					font-size: ($subh0Size)*.6;	
					padding-top: rem(10px);				
					padding-bottom: rem(20px);
				}
				@include breakpoint(medium) {
					padding-bottom: rem(30px);
				}
				@include breakpoint(large) {
					font-size: ($subh0Size)*.7;
					padding-bottom: rem(40px);
				}
				@include breakpoint(giant) {
					font-size: ($subh0Size)*.8;
					padding-bottom: rem(50px);
				}
				@include breakpoint(full) {
					font-size: $subh0Size;
					padding-bottom: rem(60px);
				}
			}
		}
	}	
}
// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	@include breakpoint(large,max) {
		.section, .sectionSmall {
			padding-left: 7vw;
			padding-right: 7vw;
			.section, .sectionSmall {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	
	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList), 
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
	
	.imgBox {
		background: $dark;
		min-height: rem(300px);
		&.immoOne {
			.index & {
				@include responsiveImage('/images/index/immobilien1.jpg', (tiny, large), true);
				&.lazyLoaded {
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
			.verpachtung & {
				@include responsiveImage('/images/verpachtung/sectionOneBg.jpg', (tiny, large), true);
				&.lazyLoaded {
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
			@include responsiveImage('/images/layout/immobilien4.jpg', (tiny, large), true);
			&.lazyLoaded {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
		&.immoTwo {
			.index & {
				@include responsiveImage('/images/index/immobilien2.jpg', (tiny, large), true);
				&.lazyLoaded {
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
			.verpachtung & {
				@include responsiveImage('/images/verpachtung/sectionTwoBg.jpg', (tiny, large), true);
				&.lazyLoaded {
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
			@include responsiveImage('/images/layout/immobilien2.jpg', (tiny, large), true);
			&.lazyLoaded {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
		&.immoThree {
			.index & {
				@include responsiveImage('/images/verpachtung/sectionOneBg.jpg', (tiny, large), true);
				&.lazyLoaded {
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
			.verpachtung & {
				@include responsiveImage('/images/verpachtung/sectionThreeBg.jpg', (tiny, large), true);
				&.lazyLoaded {
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
			@include responsiveImage('/images/layout/immobilien3.jpg', (tiny, large), true);
			&.lazyLoaded {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				@include breakpoint(large, max) {
					.index & {
						margin-bottom: rem(100px);
					}
				}
			}
		}
	}

	.offerFrame {
		width: 100%;
		height: rem(800px);
	}
}

.orangeBtn{

	a {
		background-color: $secondary;
	}
	i {
		transform: translate(0, -2px);
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	.footerBox {
		position: relative;
		@extend .teaserBox;
		@include responsiveImage('/images/index/footerBG.jpg', (tiny, large), true);
		&.lazyLoaded {
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
		}
		@include breakpoint(tiny, max) {
			&:before {
				content: "";
				position: absolute;
				left: 0; right: 0;
				top: 0; bottom: 0;
				background: rgba(0,0,0,.3);
				z-index: 10;
			}	
		}
		
		.scroll & {
			margin-top: 0;
		}
		.teaserBoxShade {
			z-index: 100;
			padding-left: 30px;
			padding-right: 30px;
			@include breakpoint(tiny) {
				padding-top: rem(50px);
				padding-bottom: rem(50px);
				padding-left: 0;
				padding-right: 0;
			}
			@include breakpoint(large) {
				padding-top: rem(75px);
				padding-bottom: rem(75px);
			}
			@include breakpoint(giant) {
				padding-top: rem(100px);
				padding-bottom: rem(100px);
			}
			@include breakpoint(huge) {
				padding-top: rem(150px);
				padding-bottom: rem(150px);
			}
			@include breakpoint(full) {
				padding-top: rem(240px);
				padding-bottom: rem(240px);
			}
			@include breakpoint(fullHD) {
				padding-top: rem(315px);
				padding-bottom: rem(315px);
			}
		}
	}
	.breakRight {		
		.col.large-auto {
			padding: 0;
		}
		.col.huge-10.huge-suffix-2 {
			@include breakpoint(giant) {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $baseFontSize;
			}			
		}
		.h3 {
			font-weight: normal;
			color: $secondary;
			margin-bottom: rem(5px);
			margin-top: 1em;
			@include breakpoint (small) {
				margin-top: -5em;
			}
			@include breakpoint (medium) {
				margin-top: -4em;
			}
			@include breakpoint (large) {
				margin-top: -6em;
				margin-left: 2em;
			}
			@include breakpoint (fullHD) {
				margin-top: -6em;
				margin-left: 0;
			}

		}	
		.sectionVerySmall {
			@include breakpoint (medium) {
				position: relative;
				top:5em;
			}
			@include breakpoint (large) {
				position: relative;
				left:-7em;
			}
			@media (min-width: 1200px) and (max-width: 1364px) {
				position: relative;
				left:-5em;
			 }
			 @include breakpoint (huge) {
				position: relative;
				left:-5em;
			}
			@include breakpoint (full) {
				position: relative;
				top:2em;
			}
			@include breakpoint (cut) {
				position: relative;
				top:2em;
				left:5em;
			}
			@media (min-width: 1620px){
				position: relative;
				left:-5em;
			 }
			@include breakpoint (fullHD) {
				position: relative;
				top:2em;
				left:-7em!important;
			}
			
			
			
			.footerItem {
				@include breakpoint (large) {
					padding-bottom: rem(18px);
				}
			}
		}	
	}
	dl.openingHours {
		display: inline-flex;
		@include breakpoint (giant, max) {
			display: flex;
			flex-direction: column;
		}
		dt,dd {
			display: flex;
			@include breakpoint(large) {
				font-size: ($baseFontSize)*.8;
			}
			@include breakpoint(giant) {
				font-size: ($baseFontSize)*.9;
			}
			@include breakpoint(fullHD) {
				font-size: rem(15px);
			}			
		}
		dt {
			font-weight: 400;
			white-space: nowrap;
			padding-right: rem(5px);
		}
	}

	.h3 {
		span {
			@include breakpoint(large) {
				hyphens: none;
			}
		}
	}

	.googleMaps {

		@include breakpoint(medium) {
			min-height: rem(200px);
		}
		@include breakpoint(large) {
			height: 100%!important;
			padding-bottom: 0!important;
		}
	}

	.section{
		@include breakpoint (large){
			padding-bottom: 6.25rem;
			padding-top:12.25rem;
		}
	}
	.btn {

		@include breakpoint (tiny, max) {
			padding: rem(10px) rem(10px) rem(10px) 0;
		}		
	}

	.listed{
		@include breakpoint (default) {
			width: auto;
			height: 130px;
			left: 30%;
			margin-top: 40px;
			position: relative;
		}

		@include breakpoint (tiny) {
			width: auto;
			height: 150px;
			left: 33%;
			margin-top: 20px;
			position: relative;
		}
		@include breakpoint (small) {
			width: auto;
			height: 95px;
			top:15px;
			left: 20px;
			margin-top: 20px;
			position: relative;
		}

		@include breakpoint (medium) {
			width: auto;
			height:95px;
			left: 20px;
			top:40px;
			margin-top: 0px;
			position: relative;
		}
		
		@include breakpoint (large) {
			width: auto;
			height: 110px;
			left: 20px;
			// margin-top: 20px;
			position: relative;
		}

		@include breakpoint (giant) {
			width: auto;
			height: 95px;
			left: 20px;
			// margin-top: 20px;
			position: relative;
		}

		@include breakpoint (huge) {
			width: auto;
			height: 90px;
			left: 20px;
			// margin-top: 20px;
			position: relative;
		}

		@include breakpoint (full) {
			width: auto;
			height: 100px;
			left: 20px;
			margin-top: -5px;
			position: relative;
		}
		@include breakpoint (fullHD) {
		 	width: auto;
			height: 100px;
			left: -13px!important;
			margin-top: -5px;
			position: relative;
		}

		@include breakpoint (cut) {
			width: auto;
			height: 100px;
			left: 20px;
			// margin-top: 20px;
			position: relative;
		}
	}
}

/*==================================== 
  POP-UP
=====================================*/
.popUpContainer {
 
	body:not(.cmsBackend) & {
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		overflow: hidden;
		transition: opacity .3s, visibility .3s;
		position: fixed;
		visibility: visible;
		opacity: 1;
		z-index: 1000000;
 
		.popUpContent {
            width: 100%;
			// background: $light;
			max-height: 80vh;
			@include breakpoint (medium) {
				max-height: 90vh;
			}
			max-width: calc(100% - #{rem(60px)}); // for the close label
	    	overflow: hidden;
	        overflow-y: auto;
			background-color: transparent;
	 
			@include breakpoint(giant) {
				max-width: 80%;
			}
	 
			@include breakpoint(full) {
				max-width: 70%;
			}
		}
	}
 
	label[for="popUpToggled_bieten"] {
		display:none;
 
		body:not(.cmsBackend) & {
			background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			padding: rem($baseGap);
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(35px);
				width: rem(35px);
				position: relative;
				transition: background .3s;
	 
				&:hover {
					background: rgba(black, 0.8);
	 
					&:before, &:after {
						background: white;
					};
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(30px);
					height: rem(2px);
					background: #ddd;
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}

	label[for="popUpToggled_01"] {
		display:none;
 
		body:not(.cmsBackend) & {
			background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			padding: rem($baseGap);
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(35px);
				width: rem(35px);
				position: relative;
				transition: background .3s;
				margin-top: rem(50px);

				@include breakpoint (large) {
					margin-top: 5rem;
				}

				@include breakpoint (huge) {
					margin-top: 7rem;
				}

				&:hover {
					background: rgba(black, 0.8);
	 
					&:before, &:after {
						background: white;
					};
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(30px);
					height: rem(2px);
					background: #ddd;
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
}
 
input[id^="popUpToggled"] {
	display: none;
 
	&:not(:checked) {
 
		+ {
 
			.popUpContainer {
				position: relative;
				opacity: 0;
				visibility: hidden;
				z-index: -1000000;
				transform:scale(0);
				pointer-events: none;
				width: 0;
				height: 0;
				
			}
		}
	}
}

.pdf_container {
	margin-left: auto;
	margin-right: auto;
	padding-right: 1rem;
	padding-top: 5rem;
	padding-bottom: 5rem;
    max-width: 101.25rem;
    width: 100%;
    position: relative;
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
	align-items: stretch;

	hr {
		width: 90%;
	}
	

}

.versicherung-titel {
	margin-left: auto;
	margin-right: auto;
	padding-top: 5rem;
    max-width: 101.25rem;
    width: 100%;
    position: relative;
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
	align-items: stretch;

}


// .popUpContainer {
// 	.popUpContent {
// 		padding: rem(10px) rem(15px) rem(10px) rem(10px);
// 		@include breakpoint(medium) {
// 			padding: rem(20px);
// 		}
// 	}
// 	button {
// 		margin-bottom: rem(20px);
// 	}
// }

.columnCountRadio {
	column-count: 1;
	margin-bottom: $baseGap;
	@include breakpoint(tiny) {
		column-count: 2;
	}
	@include breakpoint(large) {
		column-count: 4;
	}
	input {
		display: none !important;
		&:checked+label {
			filter: grayscale(0);
			transition: all .2s;
		}
		&:checked+label>img {
			box-shadow: 0px 5px 20px -3px rgba($dark, .60);
			transition: all .2s;
		}
	}
	label {
		padding: 0 !important;
		margin: 0 !important;
		filter: grayscale(100%);
		&:hover {
			cursor: pointer;
		}
	}
}

.columnCountRadioThree {
	column-count: 1;
	@include breakpoint(large) {
		column-count: 3;
	}
}


fieldset {
	margin: 0 !important;
}

.textForm {
	font-size: 1rem;
	display: flex;
	justify-content: center;
	margin: .5rem;
	text-transform: uppercase;
}

.form-switch {
	textarea {
		min-height: 150px;
	}
	label {
		font-size: 1rem !important;
		font-weight: 600 !important;
		text-align: center;
	}
	.columns {
		display: grid;
		justify-content: center;
		grid-template-columns: repeat(1, 1fr);
		@include breakpoint(medium) {
			grid-template-columns: repeat(3, 1fr);
		}
		gap: $baseGap;
		.textmiddle {
			grid-column-start: 2;
		}
	}
	.acceptDSE {
		text-align: left;
		font-size: .8rem !important;
		@include breakpoint(medium) {
			font-size: 1rem !important;
		}
		line-height: 1.3rem;
	}
}

.label-wrap {
	display: flex;
	align-items: center;
}

hr.noMarginBottom {
	margin: 0!important;
}

body.wertermittlung {
	#pageWrap {
		form.default {
			@include breakpoint(giant) {
				padding:2rem!important;
			}
		}
	}	
}