// Form styles
form.default {
    overflow: hidden;
	width: 100%;
	font-family: $mainFont;
	font-weight:300;
	box-shadow:0 0 20px rgba(black,0.15);
	padding:rem($baseLineHeight);
	background:$light;

	@include breakpoint(huge) {
		padding:rem($baseLineHeight)*2;
	}

    &.disabled {
        input:not([id^="popUpToggled"]), textarea, select, button, label:not([for^="popUpToggled"]) {
            pointer-events: none; opacity:0.5;

            a {
                pointer-events: all;
            }
        }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $dark;
    }

    ::-moz-placeholder { /* Firefox 19+ */ 
        color: $dark;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: $dark;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $dark;
    }

    fieldset {
        border:none;
        margin:0 0 rem(15px) 0;

        @include breakpoint(large) {
            margin-bottom: 0;
        }

        .col {
            position: relative;
        }
    }

    legend {
        color: $secondary;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: rem(20px);
    }

    label:not([for^="popUpToggled"]) {
        color: inherit;
        display: block;
        padding: 0 0 rem(9px) 0;
        position:relative;
        hyphens: auto;
        font-size: rem(13px);
        line-height: rem(13px);
        font-weight: 500;

        @include breakpoint(large) {
            font-size: rem(14px); line-height: rem(14px);
        }

        small {
            color:$alert;
        }

        &.error {
            animation: error 1s;
            color: $alert;
            margin: 0!important;
            padding: 0!important;
            text-align: right;
            position: absolute;
            bottom: rem(10px);
            right: rem(18px);
            background: none!important;
            font-weight: 300!important;
            font-size: rem(13px)!important;
            line-height: rem(13px)!important;
        }
        &[for="Datenschutz"], &[for="Rueckruf"], &[for="privacy"], &[for="privacy2"] {
            float:left;
            background:rgba($border,0.2);
            padding: rem(12px) rem(20px) rem(12px);
            width: 100%;
            font-weight: 300;
            font-size: rem(14px);
            line-height: rem(24px);
            margin-top: rem(3px);
            margin-bottom: rem(27px);

            @include breakpoint(large) {
                font-size: rem(16px);
                line-height: rem(28px);
            }
            &.error {
                bottom: rem(-16px);
                right: 0;
            }
            .ipad & {
                padding: rem(7px) rem(20px) rem(12px);

                input:not([id^="popUpToggled"]) {
                    transform: translateY(5px);
                }
            }
        }
        &[for="Rueckruf"] {
            z-index: 1;
        }
    }

    [type="checkbox"]:not([id^="popUpToggled"]) {
        display: inline-block; 
        margin: 0 rem(10px) 0 0;
        width: auto;
        transform: translateY(1px);
    }

    [type="text"], [type="tel"], [type="email"], [type="file"], input:not([id^="popUpToggled"]), textarea, select {
        background: none;
        border: none;
        border-bottom: rem(1px) solid $border;
        border-left: rem(3px) solid $border;
        color: $dark;
        display: block;
		font-family: $mainFont;
		font-weight:300;
        margin: 0 0 rem(27px) 0;
        padding: rem(11px) rem(18px) rem(12px);
        position: relative;
        resize: none;
        width:100%;
        transition: background 300ms ease-in-out, border 300ms ease-in-out;
        font-size: rem(14px); line-height: rem(24px);
        border-radius: 0;

        @include breakpoint(large) {
            font-size: rem(16px);
            padding: rem(13px) rem(18px) rem(14px);
        }

        &.req {
            border-left: rem(3px) solid $alert;
        }

        &:focus, &:hover {
            border-color: $secondary;
            outline: none;
        }
    }
    span.selecting {
        position: relative;
        display: block;

        select {
            appearance: none;
            -webkit-appearance:none;
            -moz-appearance:none;
            -ms-appearance:none;
        }
        &:before {
            content: "";
            border-top: rem(10px) solid $dark;
            border-left: rem(7px) solid transparent;
            border-right: rem(7px) solid transparent;
            border-bottom: 0;
            position: absolute;
            right: rem(20px);
            top: 44%;
            width: 0;
            height: 0;
        }
    }
    .req {
        position: relative;
        &:before {
            content: '';
            width: rem(3px);
            height: 100%;
            background:$alert;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    button, [type="submit"], .button {
        @extend %animatedTransform;
        background: $secondary;
        border: none;
        color: $light;
        cursor: pointer;
        display: inline-block;
        font-size: rem(14px);
        line-height: rem(24px);
        padding: rem(10px) rem(15px) rem(9px);
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-family: $mainFont;
        color: $light;

        @include breakpoint(giant) {
          font-size: rem($baseFontSize); 
          line-height: rem($baseLineHeight);
        }

        @include breakpoint(full) {
          padding: rem(15px) rem(25px) rem(14px); 
        }
        &:hover, &:active, &:focus {
            background: $light; 
            color: $secondary;
            box-shadow: $baseShadow;
            @include breakpoint(full) {
                font-size: rem($baseFontSize)*1.1; 
            }
        }

        &[disabled="disabled"] {
            opacity:0.3;
            pointer-events: none;
        }
    }
	.errorContainer {position: relative;}
	
	#filesContainer {

		> div {
			display: flex;

			span {
				display:inline-flex;
				width: rem(30px);
				height: rem(30px);
				border-radius: 100%;
				background:$alert;
				flex-shrink:0;
				flex-grow:0;
				color:$light;
				align-items: center;
				justify-content:center;
				margin-left: rem(10px);
				cursor: pointer;
				transition:background 0.3s;

				&:after {
					font-family: $iconFont;
					content: map-get($iconMap, times);
				};

				&:hover {
					background:darken($alert,10%);
				}
			}
		}
	}

	#addFile {
		margin: 0 0 rem(27px) 0;
	}
}

/*=VALIDATION */

@keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.specialfield {
    display: none !important;
    visibility:hidden !important;
}

#newsletter-info {
    background: $alert; color: $light; font-weight: bold; margin-bottom: rem(20px); padding: rem(15px) rem(25px); text-align: center;

    *:last-of-type {
        margin-bottom: 0;
    }
}

img[name=vimg] + input[name=imgverify] {
    float:right;
}
