/*
////////////////////////////////////////////
//// VARIABLES
////////////////////////////////////////////
*/
:root {
  --fs-font-size: #{$fs-font-size};

  --fs-color-primary: #{$fs-color-primary};
  --fs-color-font: #{$fs-color-font};
  --fs-color-headline: #{$fs-color-headline};
  --fs-color-light: #{$fs-color-light};
  --fs-color-medium: #{$fs-color-medium};
  --fs-color-border: #{$fs-color-border};
  --fs-color-progress: #{$fs-color-progress};
  --fs-color-success: #{$fs-color-success};
  --fs-color-warning: #{$fs-color-warning};
  --fs-color-warning-rgb: #{$fs-color-warning-rgb};
  --fs-color-error: #{$fs-color-error};
  --fs-color-error-rgb: #{$fs-color-error-rgb};

  --fs-popup-width: #{$fs-popup-width};
  --fs-popup-wrap-width: #{$fs-popup-wrap-width};
  --fs-popup-color-overlay: #{$fs-popup-color-overlay};
  --fs-popup-color-background: #{$fs-popup-color-background};
}

/*
////////////////////////////////////////////
//// DEFAULT
////////////////////////////////////////////
*/
html.no-scroll {
  overflow: hidden !important;
}

.form-switch:not(.widget):not(.active) {
  display:none;
}

.form-switch {
  position: relative;
  display: block;
  color: var(--fs-color-font);
}

.form-switch .close:hover,
.form-switch .back-button:hover {
  opacity: 0.4;
}

.form-switch [data-form] {
  position: relative;
  margin-bottom: 0;
}

.form-switch [data-if] {
  transition: opacity 500ms linear;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}

.form-switch [data-if].visible {
  visibility: visible;
  opacity: 1;
  max-height: unset;
}

.form-switch canvas:not(.animation) {
  width: 100%;
  margin-bottom: 10rem;
}

.form-switch .continue-button {
  display: block;
  margin: 0 0 0 auto;
  width: min-content;
}

.form-switch .back-button {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem 1rem;
  color: var(--fs-color-font);
  font-size: 0.8rem;
  border: 1px solid var(--fs-color-border);
  transition: 300ms ease;
}

.form-switch .skip-button {
  @include skip-button
}

.form-switch error-valide {
  display: block;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  background-color: rgba(var(--fs-color-error-rgb), 0.1);
  color: var(--fs-color-error);
  line-height: 1.2em;
}

.form-switch [data-form="waitingScreen"] .wrap,
.form-switch [data-form="endScreen"] .wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
}

.form-switch [data-form="waitingScreen"] .animation {
  max-width: 8rem;
  height: auto;
  color: var(--fs-color-progress);
  animation: waiting-spin 1000ms infinite cubic-bezier(0.7, 0.4, 0.5, 0.7);
}

@keyframes waiting-spin {
  from {
    transfrom: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-switch .wrap [data-icon="check"] {
  max-width: 2rem;
  color: var(--fs-color-success);
}

.form-switch .radio {
  column-count: 2;
}

@include breakpoint(medium) {
  .form-switch .radio {
    column-count: 3;
  }
}

.form-switch .radio label {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0.5rem 0;
}

.form-switch .radio label input[type="radio"] {
  display: inline-block;
}

body.iphone .form-switch .radio label input[type="radio"] {
  margin: 0 0.5rem 0 0;
}

.form-switch legend {
  margin: 1rem 0;
  color: var(--fs-color-headline);
  font-size: 1.5rem;
  line-height: 1.2em;
}

.form-switch legend span {
  display: block;
  font-size: 0.5em;
  line-height: 1.2em;
}

.form-switch label {
  display: block;
  margin: 0.5rem 0;
  color: var(--fs-color-primary);
}

.form-switch .range-slider {
  width: 100%;
}

.form-switch .range-slider .slider {
  appearance: none;
  width: 100%;
  height: 0.25rem;
  padding: 0;
  background-color: var(--fs-color-medium);
  border: none;
  border-radius: 1rem;
  outline: none;
  opacity: 1;
  transition: opacity 2.2s;
}

.form-switch .range-slider .slider:hover {
  opacity: 0.7;
}

.form-switch .range-slider .slider::-webkit-slider-thumb {
  -webkit-appearence: none;
  appearance: none;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  background-color: var(--fs-color-primary);
  border-radius: 50%;
}

.form-switch .range-slider .slider::-moz-range-thumb {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  background-color: var(--fs-color-primary);
  border-radius: 50%;
}

.form-switch .range-slider .number-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
textarea,
select {
  position: relative;
  display: inline;
  width: 100%;
  padding: 0.8rem;
  color: var(--fs-color-font);
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5em;
  border: 2px solid #ddd;
  border-radius: 0;
  resize: none;
  &.slider-count {
    width: 45%;
    border: none !important;
    margin: auto;
  }
}

/*
////////////////////////////////////////////
//// WIDGET
////////////////////////////////////////////
*/
.form-switch.widget .close {
  display: none;
}

.form-switch.widget [data-form] {
  margin-block: 4rem;
  padding-top: 4rem;
}

.form-switch.widget .back-button {
  @include widget-back-button;
}

.form-switch.widget [data-form] {
  @include widget-form-page;
}

/*
////////////////////////////////////////////
//// POPUP
////////////////////////////////////////////
*/
.form-switch:not(.widget) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--fs-popup-color-overlay);
  z-index: 1100;
}

.form-switch:not(.widget) [data-active] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: var(--fs-popup-width);
  max-height: 80vh;
  padding: clamp(1rem, 4vw, 4rem);
  padding-top: clamp(5rem, 6vw, 6rem);
  background-color: var(--fs-popup-color-background);
  border: 0;
  overflow-y: auto;
}

.form-switch:not(.widget) .close {
  cursor: pointer;
  position: absolute;
  top: clamp(1.5rem, 2.5vw, 2.5rem);
  right: clamp(1rem, 4vw, 4rem);
  width: 1.5rem;
  height: 1.5rem;
  color: var(--fs-color-font);
  font-size: 1rem;
  transition: 300ms ease;
  text-decoration: none;
  text-indent: -100vw;
  
  @include popup-close-button;
}

.form-switch:not(.widget) .close::before,
.form-switch:not(.widget) .close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: var(--fs-color-font);
}

.form-switch:not(.widget) .close::before {
  transform: translateY(-50%) rotate(45deg);
}

.form-switch:not(.widget) .close::after {
  transform: translateY(-50%) rotate(-45deg);
}

.form-switch:not(.widget) .back-button {
  top: clamp(1rem, 2vw, 2rem);
  left: clamp(1rem, 4vw, 4rem);

  @include widget-back-button;
}

.form-switch:not(.widget) [data-form] {
  @include popup-form-page;
}

.form-switch [data-form]:not([data-active="true"]):not(.widget) {
  display: none;
}

/*
////////////////////////////////////////////
//// MODULE
////////////////////////////////////////////
*/
.warning {
  display: block;
  margin: 2rem 0;
  padding: 1.5rem 2rem;
  color: var(--fs-color-warning);
  background-color: rgba(var(--fs-color-warning-rgb), 0.2);
  text-align: center;
}

/*
////////////////////////////////////////////
//// FLATPICKR
////////////////////////////////////////////
*/
.flatpickr-calendar.open.animate {
  z-index: 9999999999;
}

.form-switch .anrede input, .form-switch .anrede label {
  display: inline !important;
  width: auto !important;
}

.form-switch label {
  display: flex;
  justify-content: center;
}

.input-group {
  height: 50px;
  display: flex;
  justify-content: center;
  border: 2px solid #ddd;
}
.input-group:nth-of-type(1){
  border-right: none;
  font-weight: 600;
}
.input-group:nth-of-type(2){
  border-left: none;
  border-right: none;
}
.input-group:nth-of-type(3){
  border-left: none;
  font-weight: 600;
}

