// OVERVIEW PAGE // index.php

.dcOverviewItem {
    font-size: rem(17px);
    line-height: rem(27px);
    display:flex;
    width: 100%;

    a {
        border:1px solid $border;
        padding:rem($baseGap);
        display:flex;
        flex-direction: column;
        text-decoration: none;
        width:100%;
        color:$dark;
        font-size: rem(16px);
        line-height: rem(26px);
        transition:border 0.3s;
        margin-bottom: rem($baseGap)*2;

        &:hover {
            border:1px solid $secondary;
        }

        img {
            margin-bottom: rem($baseGap);
        }

        .title {
            font-size: rem(16px);
            line-height: rem(26px);
            color:inherit;
            font-weight: 500;
            margin:0;
            padding-bottom: rem(8px);
        }

        .details {
            font-size: rem(14px);
            line-height: rem(24px);
            padding-top:rem($baseGap);
            display:flex;
            justify-content: space-between;
            font-weight: 500;
            margin-bottom: 0;
            margin-top:auto;
        }
    }

    // VERSTEIGERUNG

    &.dcOverviewItem_versteigerung {

        a {

            .details {
                padding-top:0;
                font-weight: 300;
                flex-direction: column;

                li {

                    &:not(:last-child) {
                        margin-bottom: rem(10px);
                    }

                    .price {
                        color:$secondary;
                    }

                    .offers {
                        padding-left:rem(10px);
                    }
                }
            }
        }
    }
}

.dcCategoryNav {

    li {
        padding:0;
        margin:0;

        a {
            color:$dark;
            margin-top:rem(10px);
            display:block;
            text-decoration: none;
            background: rgba($medium, 0.1);
            padding:rem(10px);

            &.active {
                background:$secondary;
                color:$light;
                font-weight: 500;
            }
        }
    }    

    > li {

        &:last-of-type {
            display: none;
        }

        a {

            &:before, &:after {
                display: none!important;
            }
        }

        > ul {

            li {

                a {
                    padding-left: rem(20px);

                    &:before {
                        display:inline-block!important;
                        font-family: $iconFont;
                        content: map-get($iconMap, angle-right);
                        position: relative;
                        transform:translateY(2px)
                    }
                    
                }
                
            }
        }
    }

    .dcSubCategories {
        margin-bottom: 0;
    }
}

// SINGLE PAGE

.dc_wrapper {

    h1 {
        border-bottom:rem(1px) solid $border;
        padding: 0 0 rem($baseGap);
        margin: 0 0 rem($baseLineHeight);
        font-size: clamp(26px, 4vw, 45px);
    }

    h2 {
        margin: 0 0 rem($baseLineHeight);
        font-size: clamp(22px, 4vw, 30px);
        color:$dark;
    }

    .restzeit {
        display:flex;
    }

    .bietenWrapper {
        // box-shadow:0 0 20px rgba(black,0.15);
        // padding:rem($baseLineHeight);
        // background:$light;

        // @include breakpoint(huge) {
        //     padding:rem($baseLineHeight)*2;
        // }

        .aktuelles_gebot {
            display:flex;
            align-items: flex-end;

            span {
                display:block;
            }

            .price {
                font-size: clamp(25px*.65, 4vw, 25px);
                line-height: 1;
                color:$secondary;
            }

            .offers {
                line-height: 1;
                padding-left:rem(10px);
                font-size: clamp(16px*.75, 4vw, 16px);
                padding-bottom: rem(1px);
            }
        }

        .popUpContent {

            @include breakpoint(huge) {
                padding:rem($baseLineHeight)*2;
            }

            @include breakpoint(full) {
				max-width: rem(600px)!important;
			}
        }
    }

    .infoBox {
        padding:rem($baseLineHeight);
        background:$light;
        box-shadow:0 0 20px rgba(black,0.15);
        display:block;
        width:100%;

        .headline {
            color:$primary;
            font-size: rem(25px);
            line-height: 1;
            border-bottom:1px solid $border;
            padding-bottom: rem(14px);
            margin-bottom: rem(14px);
        }
    }

    .dc-details {

        dt, dd {
            display:block;
            float:left;
        }
    
        dt {
            font-weight: 500;
            clear:left;
            width:rem(120px);
            @include breakpoint(tiny) {
                width:rem(200px);
            }
        }
    
        dd {
            
            &:not(.last-of-type) {
                padding-bottom: rem(10px);
            }
        }
    }

}

#dc-detailed-slideshow {
    margin-bottom: rem($baseGap) / 2; 
    position: relative;
    overflow:hidden;

    img {
        margin-bottom: 0;
    }

    &__nav {

    	> div {
            background: $primary; 
            font-size: 1rem;
            color:$light;
            line-height: 1;
		    display: block;
		    background: $primary;
		    color: #fff;
		    padding: 1rem;
		    text-decoration: none;
		    transition: .3s;
		    cursor: pointer;
		    position: absolute;
		    top:50%;
		   	transform:translateY(-50%);
		   	z-index: 1;

		    &:hover {
		    	background:$secondary;
		    }

            &.dc-detailed-slideshow-button-prev {
				left:0;
            }

            &.dc-detailed-slideshow-button-next {
				right:0;
            }	 
        }
    }
}

#dc-detailed-slideshow__images {

    img {
        margin-bottom: 0;
    }
}

#openStreetMap {
    height:rem(500px);
}

.dcPagination {
    display: flex;
    justify-content: space-between;
    padding-top:rem($baseLineHeight);

    a {
        display: inline-block;
        text-decoration: none;
        padding: rem(6px) rem(16px);
        background: $dark;
        color: $light;
        font-weight: 500;

        &:hover {
            background:$secondary;
            color:$light;
        }
    }
}