* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
  background: $light;
  box-sizing: border-box;
  color: $medium;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  font-weight: lighter;
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;

  &:not(.javascript) * {
    transition:none!important;
  }
}

body.popup-open { overflow: hidden }

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

b, strong {
  font-weight: 500;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: rem($baseLineHeight);
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  font-weight: 500;
  line-height: 1.2em;
  @include breakpoint(large) {
    margin-bottom: rem($baseLineHeight)*1.75;
  }

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
  span {
    color: $primary;
    font-weight: 400;
    display: block;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1620 - 320)));
    line-height: 1.3em;
  }
}

h1,
.h1 {
  font-family: $displayFont;
  font-size: clamp(26px, 4vw, 56px);
  color: $secondary;
  @include breakpoint(large) { 
    hyphens: none;
  }
  span {
    font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1620 - 320)));
  }
}

h2,
.h2 {
  font-size: calc(26px + (46 - 26) * ((100vw - 320px) / (1620 - 320)));
  color: $secondary;
  font-weight: 500;
  hyphens: none;
}

h3,
.h3 {
  font-size: calc(24px + (26 - 24) * ((100vw - 320px) / (1620 - 320)));
    @include breakpoint(large) { 
      margin-left: 2em;
    }
    @include breakpoint(medium) { 
      margin-left: 2em;
      margin-top: 2em;
    }
    @include breakpoint(small) { 
      margin-left: 6em;
    }
  }

h4,
.h4 {
  font-size: calc(22px + (24 - 22) * ((100vw - 320px) / (1620 - 320)));
}

h5,
.h5 {
  font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1620 - 320)));
}

h6,
.h6 {
  font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1620 - 320)));
}

/**
 * Links
 */
a {
  color: $secondary;
  word-wrap: break-word;
  text-decoration: none;
  transition: all 350ms ease-in-out;

  &:hover {
    color: $primary;
    transition: all 350ms ease-in-out;
  }
  &:focus {
    color: darken($secondary, 50%);
  }
  &:active {
    color: darken($secondary, 30%);
  }

  img {
    border: none;    
  }

  &[href^="tel"] {
    color: inherit;
    //text-decoration: underline;
  }
}

.teaserHeadline,
.contactInfo {
  font-weight: 500;
}
.teaserSubHeadline {
  font-weight: 400;
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,.hr {
	display:block;
	width:100%;
	background:none!important;
	height:1px;
  border:none;
  margin:0!important;
	border-bottom:1px solid $border;
	margin-bottom: rem($baseLineHeight)!important;

	@include breakpoint(medium) {
		margin-bottom: rem(40px)!important;
		padding: calc(#{rem(40px)} - #{rem($baseLineHeight)}) 0 0 0;
	}

	@include breakpoint(full) {
		margin-bottom: rem(60px)!important;
		padding: calc(#{rem(60px)} - #{rem($baseLineHeight)}) 0 0 0;
	}

}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(15px);
      margin-bottom: rem(7px);
      position: relative;

      &:before {
        @extend .icon;
        @extend .icon-angle-right;
        position: absolute;
        transform: translate(rem(-20px), rem(10px));
        @include breakpoint(medium) {
          transform: translate(rem(-20px), rem(8px));
        }
        @include breakpoint(giant) {
          transform: translate(rem(-20px), rem(6px));
        }
        
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.1);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}